.HeadLine .ActionButton {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 10px;
    text-decoration: none;
}

.HeadLine .ActionButton:hover {
    opacity: 0.8;
}

.Headline .MultiSelect {
    width: 150px;
}