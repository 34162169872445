.PublisherInfos {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    margin-top: 0px;
    background: #FFFFFF;
    padding: 25px;
}

.PublisherInfos .LastContactContainer {
    color: #898989;
}

.PublisherInfos .LabelContainer {
    width: 30%;
}

.PublisherInfos .SelectField {
    width: 200px;
}

.PublisherInfos .Reminder {
    font-size: 18px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    border: none;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
}

.PublisherInfos .Reminder:hover {
    background: #fbd0d5;
    transition: all 0.3s;
}

.ContainerDayPicker {
    position: relative;
}

.ContainerDayPicker .ReminderObject {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    background: #FFFFFF;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
}

.IconLinkMoneybox {
    height: 35px;
}

.IconLinkMoneybox:hover {
    opacity: 0.8;
}

.PerfSinceLastBill {
    font-weight: 900;
}