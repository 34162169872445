.CSMUserDetails {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #ffffff;
    padding: 25px;
    text-align: center;
}

.CSMUserDetails .Avatar {
    width: 100px;
    border-radius: 50%;
}

.CSMUserDetails .AvatarAlt {
    height: 100px;
}