.Alert {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.AlertList tr th {
    text-align: left;
}

.Alert tr:nth-child(odd){
    background-color: #f5f5f5;
}

.ContainerAlertsListTable {
    max-height: 400px;
    overflow-y: scroll;
}