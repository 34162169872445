.CSMCrewDetailsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    width: 100%;
    margin-top: 20px;
}

.CSMCrewDetails .DayPickerObject {
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    margin-left: 0;
    margin-right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.CSMCrewDetails {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #f5f5f5;
    padding: 25px;
}