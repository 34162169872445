@import url('https://fonts.googleapis.com/css2?family=Livvic&family=Poppins&display=swap');

.App {
  background: #F1E9E9;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.Subtitle {
  font-size: 14px;
}

.FlexRow {
  display: flex;
}

.FlexWrap {
  flex-wrap: wrap;
}

.FlexCol {
  display: flex;
  flex-direction: column;
}

.FullWidth {
  width: 100%;
}

.HalfOpacity {
  opacity: 0.5;
}

.FlexBottom {
  align-items: flex-end;
}

.FlexSpaceAround {
  justify-content: space-between;
}

.FlexSpaceBetween {
  justify-content: space-around;
}

.WrapContent {
  flex-wrap: wrap;
}

.AlignCenter {
  align-items: center;
}

.JustifyBetween {
  justify-content: space-between;
}

.JustifyCenter {
  justify-content: center;
}

.JustifyAround {
  justify-content: space-around;
}

.JustifyEnd {
  justify-content: flex-end;
}

.VAlignTop {
  vertical-align: top;
}

.P5 {
  padding: 5px;
}

.P10 {
  padding: 10px;
}

.P15 {
  padding: 15px;
}

.P20 {
  padding: 20px;
}

.PL10 {
  padding-left: 10px;
}

.PL50 {
  padding-left: 50px;
}

.M0 {
  margin: 0px;
}

.MB0 {
  margin-bottom: 0px;
}

.MB5 {
  margin-bottom: 5px;
}

.MB10 {
  margin-bottom: 10px;
}

.MB15 {
  margin-bottom: 15px;
}

.MB20 {
  margin-bottom: 20px;
}

.MT0 {
  margin-top: 0px;
}

.MT5 {
  margin-top: 5px;
}

.MT10 {
  margin-top: 10px;
}

.MT15 {
  margin-top: 15px;
}

.MT20 {
  margin-top: 20px;
}

.MT40 {
  margin-top: 40px;
}

.MR5 {
  margin-right: 5px;
}

.MR10 {
  margin-right: 10px;
}

.MR15 {
  margin-right: 15px;
}

.MR20 {
  margin-right: 20px;
}

.MR40 {
  margin-right: 40px;
}

.MR50 {
  margin-right: 50px;
}

.ML5 {
  margin-left: 5px;
}

.ML10 {
  margin-left: 10px;
}

.ML15 {
  margin-left: 15px;
}

.ML20 {
  margin-left: 20px;
}

.TCenter {
  text-align: center;
}

.ActionButton {
  border-radius: 4px;
  background: #f41127;
  color: #FFFFFF;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.ActionLink {
  color: black;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  text-decoration-color: #f41127;
}

.ActionLink:visited {
  color: black;
}

.BOrange {
  background: #e67e22;
}

.BPurplePink {
  background: #8B3E64;
}

.BBlack {
  background: #222222;
}

.BBlue {
  background: #3498db;
}

.BRed {
  background: #f41127;
}

.BGreen {
  background: #2ecc71;
}

.BGrey {
  background: #c1c1c1;
}

.BYellow {
  background: #f1c40f;
}

.BPink {
  background: #ff7979;
}

.BLightGrey {
  background: #bdbdbd;
}

.BPurple {
  background: #6c5ce7;
}

.BSky {
  background: #70a1ff;
}

.BSoftPink {
  background: #EF4D77;
}

.BSoftPurple {
  background: #8B3E64;
}

.BDarkPurple {
  background: #660033;
}

.TGreen {
  color: #2ecc71;
}

.TBlack {
  color: #000000;
}

.TRed {
  color: #f41127;
}

.TPink {
  color: #ff7979;
}

.TPurple {
  color: #6c5ce7;
}

.TYellow {
  color: #f1c40f;
}

.TSky {
  color: #70a1ff;
}

.TGrey {
  color: #898989;
}

.InBlock {
  display: inline-block;
}

.Inline {
  display: inline;
}

.Block {
  display: block;
}

.Hide {
  display: none;
}

.FitContent {
  width: fit-content;
}

.HFitContent {
  height: fit-content;
}

.ButtonDisabled {
  cursor: not-allowed;
}

.ButtonMiddleSize {
  height: 35px;
}

.HalfWidth {
  width: 50%;
}

.Width0 {
  width: 0%;
}

.Width5 {
  width: 5%;
}

.Width10 {
  width: 10%;
}

.Width15 {
  width: 15%;
}

.Width20 {
  width: 20%;
}

.Width25 {
  width: 25%;
}

.Width30 {
  width: 30%;
}

.Width35 {
  width: 35%;
}

.Width40 {
  width: 40%;
}

.Width45 {
  width: 45%;
}

.Width50 {
  width: 50%;
}

.Width55 {
  width: 55%;
}

.Width60 {
  width: 60%;
}

.Width65 {
  width: 65%;
}

.Width70 {
  width: 70%;
}

.Width75 {
  width: 75%;
}

.Width80 {
  width: 80%;
}

.Width85 {
  width: 85%;
}

.Width90 {
  width: 90%;
}

.Width95 {
  width: 95%;
}

.Width100 {
  width: 100%;
}

.Width200 {
  width: 200%;
}

h1 {
  font-family: 'Poppins', sans-serif;
}

h5 {
  font-family: 'Livvic', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #898989;
}

.SmallFont {
  font-size: 12px;
}

.MediumFont {
  font-size: 18px;
}

.BigFont {
  font-size: 32px;
}

.BoldMediumFont {
  font-size: 18px;
  font-weight: bold;
}

.BSoftyRed {
  background: #ffeded;
}

.Underline {
 text-decoration: underline;
}

.NoUnderline {
  text-decoration: none;
}

.URed {
 text-decoration-color: #f41127;
}

.ActionInput {
  border: 1px solid #dbdbdb;
  border-radius: 4px 0 0 4px;
  height: 26px;
  outline: none;
  padding-left: 5px;
}