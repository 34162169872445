.Export {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Export .HeadLineExport {
    grid-area: 1 / 1 / 2 / 13;
}

.Export .EasyExport {
    grid-area: 2 / 1 / 3 / 13;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.Export .EasyExportHistory {
    grid-area: 3 / 1 / 4 / 13;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.Export .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}