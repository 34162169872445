td {
    padding: 20px 10px;
}

.ContactsRow td i {
    margin-right: 10px;
}

.ContactsRow td i.Hide {
    display: none;
}

.ContactsRow .ColDate {
    min-width: 100px;
}

.ContactsRow .ColPrio {
    min-width: 50px;
}

.ContactsRow .ColSite {
    min-width: 150px;
}

.ContactsRow .ActionButton {
    text-decoration: none;
}

.ContactsRow .ActionButton i {
    margin-right: 0px;
}

.ContactsRow .ActionButton:hover {
    opacity: 0.8;
}