.HeadlineExport .SelectField {
    width: 350px;
}

.HeadlineExport button {
    height: 36px;
}

.HeadlineExport .ExportContainer {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.HeadlineExport .ExportContainer .LeftBorderSeparator {
    border-left: 2px solid #222222;
}

.HeadlineExport .DayPickerObject {
    margin: 0px;
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
    width: fit-content;
}

.HeadlineExport .DayPickerObject .rdp-day_selected {
    background-color: #f41127 !important;
}

.HeadlineExport .DayPickerObject .rdp-day_selected:focus {
    outline: #f41127 !important;
}

.HeadlineExport .SmallNumberInput {
    width: 75px;
}



.HeadlineExport .HTMLContainer {
    background: #FFFFFF;
    position: fixed;
    height: 80vh;
    width: 50vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
}
.HeadlineExport .HTMLContainer .img {
text-align: center;
}

.HeadlineExport .HTMLContainer img {
    width: 150px;
    height: 250px;
}

.HeadlineExport .HTMLContainer h1 {
    text-align: center;
}
