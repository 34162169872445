.UsersBills {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.UsersBills .UsersBillsTable {
    overflow-y: scroll;
    width: 100%;
}

.UsersBills .UsersBillsTable table {
    width: 100%;
}

.UsersBills .UsersBillsTable th {
    text-align: left;
}