.EasyExport .RangeInput{
    filter: hue-rotate(140deg);
}

.EasyExport .SmallNumberInput {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 5px;
}

.EasyExport .MultiSelect {
    min-width: 150px;
}