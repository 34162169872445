.CRMUser {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.CRMUser .HeadLine {
    grid-area: 1 / 1 / 1 / 13;
    box-sizing: border-box;
}

.CRMUser .PublisherInfos {
    grid-area: 2 / 1 / 2 / 13;
    box-sizing: border-box;
}

.CRMUser .ProspectionCard {
    grid-area: 3 / 5 / 5 / 9;
    box-sizing: border-box;
}

.CRMUser .CommentCard {
    grid-area: 3 / 9 / 5 / 13;
    box-sizing: border-box;
}

.CRMUser .ContactCard {
    grid-area: 3 / 1 / 4 / 5;
    box-sizing: border-box;
}
.CRMUser .TimelineCard {
    grid-area: 5 / 1 / 6 / 7;
    box-sizing: border-box;
}

.CRMUser .AlertCard {
    grid-area: 5 / 7 / 6 / 13;
    box-sizing: border-box;
}

.CRMUser .Card1 {
    grid-area: 2 / 1 / 2 / 4;
    box-sizing: border-box;
}

.CRMUser .Card2 {
    grid-area: 2 / 4 / 2 / 7;
    box-sizing: border-box;
}

.CRMUser .Card3 {
    grid-area: 2 / 7 / 2 / 10;
    box-sizing: border-box;
}

.CRMUser .Card4 {
    grid-area: 2 / 10 / 2 / 13;
    box-sizing: border-box;
}

.CRMUser .DCard1 {
    grid-area: 3 / 9 / 4 / 11;
    box-sizing: border-box;
}

.CRMUser .DCard2 {
    grid-area: 3 / 11 / 4 / 13;
    box-sizing: border-box;
}

.CRMUser .DCard3 {
    grid-area: 4 / 9 / 5 / 11;
    box-sizing: border-box;
}

.CRMUser .DCard4 {
    grid-area: 4 / 11 / 5 / 13;
    box-sizing: border-box;
}

.CRMUser .SiteState {
    grid-area: 6 / 1 / 6 / 13;
    box-sizing: border-box;
}

.CRMUser .WebsitesCard {
    grid-area: 4 / 1 / 5 / 5;
    box-sizing: border-box;
}

.CRMUser .UserWebsiteCard {
    grid-area: 6 / 1 / 9 / 13;
    box-sizing: border-box;
}

.CRMUser .BillsCard {
    grid-area: 9 / 1 / 9 / 13;
    box-sizing: border-box;
}

.CRMUser .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
