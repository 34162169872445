.SelectorCommunication.FullWidth {
    width:100%;
    margin-top: 0px;
}

.SelectorCommunication.FullWidth.SelectorCommunicationAlt {
    margin-top: 5px;
}

.ListNotifTimeline {
    max-height: 400px;
    overflow-y: scroll;
}

.Timeline {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    height: fit-content;
}

.Timeline button {
    height: 26px;
}

.Button1 {
    grid-area: 1 / 1 / 1 / 2;
    box-sizing: border-box;
}
.Button2 {
    grid-area: 1 / 2 / 1 / 3;
    box-sizing: border-box;
}
.Button3 {
    grid-area: 2 / 1 / 2 / 2;
    box-sizing: border-box;
}
.Button4 {
    grid-area: 2 / 2 / 2 / 3;
    box-sizing: border-box;
}
.Button5 {
    grid-area: 3 / 1 / 3 / 3;
    box-sizing: border-box;
}

.Container1 {
    grid-area: 4 / 1 / 4 / 3;
    box-sizing: border-box;
    max-height: 360px;
    overflow-y: scroll;
}

.Container1 #textLoader{
    text-align: center;
    font-style: italic;
}

.Container1 .hide{
    display: none;
}