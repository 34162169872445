.Activation {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Activation .HeadLine {
    grid-area: 1 / 1 / 1 / 13;
    box-sizing: border-box;
}

.Activation .Card1 {
    grid-area: 2 / 1 / 2 / 4;
    box-sizing: border-box;
}

.Activation .Card2 {
    grid-area: 2 / 4 / 2 / 7;
    box-sizing: border-box;
}

.Activation .Card3 {
    grid-area: 2 / 7 / 2 / 10;
    box-sizing: border-box;
}

.Activation .Card4 {
    grid-area: 2 / 10 / 2 / 13;
    box-sizing: border-box;
}

.Activation .LineCard1 {
    grid-area: 3 / 1 / 7 / 5;
    box-sizing: border-box;
}

.Activation .LineCard2 {
    grid-area: 3 / 5 / 7 / 9;
    box-sizing: border-box;
}

.Activation .LineCard3 {
    grid-area: 3 / 9 / 7 / 13;
    box-sizing: border-box;
}

.Activation .LineCard4 {
    grid-area: 7 / 1 / 7 / 5;
    box-sizing: border-box;
}

.Activation .LineCard5 {
    grid-area: 7 / 5 / 7 / 9;
    box-sizing: border-box;
}

.Activation .LineCard6 {
    grid-area: 7 / 9 / 7 / 13;
    box-sizing: border-box;
}

.Activation .DLineCard1 {
    grid-area: 5 / 10 / 7 / 13;
    box-sizing: border-box;
}

.Activation .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}