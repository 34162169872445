.Admin .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.Admin {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Admin .EditorCard {
    grid-area: 1 / 1 / 1 / 13;
    box-sizing: border-box;
}

.Admin .CSMUserDetailsCard {
    grid-area: 2 / 1 / 2 / 13;
    box-sizing: border-box;
}