.Modal {
    position: fixed;
    top: 75px;
    left: calc(50% - 450px);
    z-index: 99999;
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    width: 900px;
    height: 80vh;
    border: 1px solid #dbdbdb;
}

.Modal .CloseContainer button {
    background: #FFFFFF;
    color: #222222;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    padding: 5px 10px;
    cursor: pointer;
}

.Modal .CloseContainer button:hover {
    background: #ffaab3;
    transition: all 0.3s;
}

.Modal .CloseContainer button i {
    font-size: 18px;
}

.Modal .HTMLContainer iframe {
    margin-top: 20px;
    width: 900px;
    border: none;
    height: calc(80vh - 85px);
    overflow-y: scroll;
}