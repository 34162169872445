.TimeLineRow {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    margin-top: 0px;
    padding: 10px 10px;
    margin-top: 10px;
    box-sizing: border-box;
    color: #000000;
    width: 100%;
    display: inline-block;
    font-size: 12px;
    border: 1px solid #dbdbdb;
}



.TimeLineRow p {
    text-align: left;
    padding: 0px;
    margin: 0px
}

.TimeLineRow .LabelContainer {
    min-width: 135px;
    text-align: right;
}