.Prospection {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.Prospection .Overflow {
    max-height: 400px;
    overflow-y: scroll;
}