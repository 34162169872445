.Ticket {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.Ticket td {
    border-bottom: 1px solid #dbdbdb;
}

.Ticket th {
    text-align: left;
}

.Ticket .TicketTable {
    width: 100%;
}

.ContainerTicketTable {
    max-height: 650px;
    overflow-y: scroll;
}