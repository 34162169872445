.Websites {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.Websites .FlexRow {
    margin: 5px;
}

.Websites p {
    display: flex;
    align-items: center;
}

.Websites .FlexRow > p:first-child {
    font-size: 14px;
    font-weight: 900;
}