.Support {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Support .HeadLine {
    grid-area: 1 / 1 / 1 / 13;
    box-sizing: border-box;
}

.Support .Card1 {
    grid-area: 2 / 1 / 2 / 4;
    box-sizing: border-box;
}

.Support .Card2 {
    grid-area: 2 / 4 / 2 / 7;
    box-sizing: border-box;
}

.Support .Card3 {
    grid-area: 2 / 7 / 2 / 10;
    box-sizing: border-box;
}

.Support .Card4 {
    grid-area: 2 / 10 / 2 / 13;
    box-sizing: border-box;
}

.Support .TicketList {
    grid-area: 3 / 1 / 7 / 9;
    box-sizing: border-box;
}

.Support .FreshdeskDateSatisfactionAndTagCard {
    grid-area: 3 / 9 / 5 / 13;
    box-sizing: border-box;
}

.Support .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}