.cardProgress {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.ProgressBarContainer {
    width: 80%;
}

.cardProgress > h2 {
    font-size: 24px;
}

.cardProgress i {
    font-size: 24px;
}