.Contact {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.Contact input, .Contact input:focus  {
    height: 26px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #dbdbdb;
    outline: none;
    padding-left: 5px;
}

.Contact button {
    height: 30px;
    width: 35px;
    border-radius: 0px 4px 4px 0px;
}

.Contact .FlexRow {
    margin: 5px;
}

.Contact p {
    display: flex;
    align-items: center;
}

.Contact .FlexRow > p:first-child {
    font-size: 14px;
    font-weight: 900;
}