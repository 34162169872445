.ProspectionRow .AbstractField {
    min-height: 50px;
    resize: none;
    font-family: 'Poppins', sans-serif;
}

.ProspectionRow {
    padding-bottom: 20px;
    border-bottom: 1px solid #f41127;
}

.ProspectionRow .FlexRow, .ProspectionRow .FlexCol {
    margin: 5px;
}

.ProspectionRow p {
    display: flex;
    align-items: center;
}

.ProspectionRow .TagsContainer {
    flex-wrap: wrap;
    gap: 10px;
}

.ProspectionRow .FlexRow > p:first-child, .ProspectionRow .FlexCol > p:first-child {
    font-size: 14px;
    font-weight: 900;
}