.UsersWebsites {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.UsersWebsites .UserWebsitesTable {
    overflow-y: scroll;
    width: 100%;
}

.UsersWebsites .UserWebsitesTable table {
    width: 100%;
}

.UsersWebsites .UserWebsitesTable th {
    text-align: left;
}