.Home {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Home .HeadLine {
    grid-area: 1 / 1 / 1 / 13;
    box-sizing: border-box;
}

.Home .Card1 {
    grid-area: 2 / 1 / 2 / 4;
    box-sizing: border-box;
}

.Home .Card2 {
    grid-area: 2 / 4 / 2 / 7;
    box-sizing: border-box;
}

.Home .Card3 {
    grid-area: 2 / 7 / 2 / 10;
    box-sizing: border-box;
}

.Home .Card4 {
    grid-area: 2 / 10 / 2 / 13;
    box-sizing: border-box;
}

.Home .DCard1 {
    grid-area: 3 / 9 / 4 / 11;
    box-sizing: border-box;
}

.Home .DCard2 {
    grid-area: 3 / 11 / 4 / 13;
    box-sizing: border-box;
}

.Home .DCard3 {
    grid-area: 4 / 9 / 5 / 11;
    box-sizing: border-box;
}

.Home .DCard4 {
    grid-area: 4 / 11 / 5 / 13;
    box-sizing: border-box;
}

.Home .SiteState {
    grid-area: 3 / 1 / 7 / 9;
    box-sizing: border-box;
}

.Home .ChartCard {
    grid-area: 5 / 9 / 7 / 13;
    box-sizing: border-box;
}

.Home .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.DCard1:hover, .DCard2:hover, .DCard3:hover, .DCard4:hover {
    opacity: 0.8;
}