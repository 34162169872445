.Contacts {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Contacts .Headline {
    grid-area: 1 / 1 / 1 / 13;
    box-sizing: border-box;
}

.Contacts .ContactsCard {
    grid-area: 2 / 1 / 2 / 13;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    padding: 25px;
}

.Contacts .ContactsCard table {
    width: 100%;
}

.Contacts .Pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
    margin-top: 20px;
    width: 800px;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
    background: #f5f5f5;
}

.Contacts .Pagination li {
    display: block;
}

.Contacts th {
    text-align: left;
}

.Contacts tr:nth-child(even) {
    background-color: #f5f5f5;
}

.Contacts .Pagination li:hover {
    opacity: 0.5;;
}