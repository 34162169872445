.CommentRowComponent {
    border-radius: 12px;
    border: 1px solid #dbdbdb;
    min-height: 75px;
    padding: 5px;
}

.CommentRowComponent textarea {
    width: 95%;
    height: 100px;
    border: none;
    resize: none;
}

.CommentRowComponent .TextContainer {
    width: 100%;
}

.CommentRowComponent .LogicContainer {
    align-items: flex-end;
    justify-content: space-around;
}

.CommentRowComponent .PinCheckbox {
    height: 20px;
    width: 20px;
}

.CommentRowComponent .LabelB0 {
    background: #222222;
}

.CommentRowComponent .LabelB1 {
    background: #f41127;
}

.CommentRowComponent .LabelB2 {
    background: #e67e22;
}

.CommentRowComponent .LabelB3 {
    background: #f1c40f;
}

.CommentRowComponent .LabelB4 {
    background: #3498db;
}

.CommentRowComponent .LabelB5 {
    background: #2ecc71;
}

.CommentRowComponent .LabelB6 {
    background: #6c5ce7;
}

.CommentRowComponent .LabelB7 {
    background: #f368e0;
}

.CommentRowComponent .LabelCardFont {
    font-size: 12px;
    border-radius: 3px;
    margin: 5px 0px;
}