.lateralNavbar {
    box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.08);
    min-height: calc(100vh - 60px);
    width: 15%;
    max-width: 300px;
    background: #FFFFFF;
    left: 0;
    top: 60px;
    bottom:0;
    position: fixed;
    z-index: 9999;
}

.lateralNavbar > ul {
    margin: 0px;
    padding-top: 1px;
    list-style-type: none;
    font-family: 'Poppins', sans-serif;
}

.lateralNavbar li {
    margin-top: 20px;
}

.lateralNavbar a {
    text-decoration: none;
    color: #222222;
}

.BurgerMenu {
    background: #FFFFFF;
    color: #222222;
    border-radius: 4px;
    padding: 10px;
    border: none;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    position: fixed;
    left: 5px;
    top: 70px;
    cursor: pointer;
    z-index: 9999;
}

.BurgerMenu:hover {
    background: #fbd0d5;
    transition: all 0.3s;
}

.lateralNavbar .CloseContainer {
    position: relative;
}

.lateralNavbar .CloseContainer button {
    position: absolute;
    top: 20px;
    right: 10px;
    background: #FFFFFF;
    color: #222222;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    padding: 5px 10px;
    cursor: pointer;
}

.lateralNavbar .CloseContainer button:hover {
    background: #ffaab3;
    transition: all 0.3s;
}

.lateralNavbar .CloseContainer button i {
    font-size: 18px;
}

.lateralNavbar .Highlight a {
    color: #f41127;
}