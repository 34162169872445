.CommentRowContainerList {
    max-height: 450px;
    overflow-y: scroll;
}

.CSMCommentContainer {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #f5f5f5;
    padding: 5px;
}

.CSMCommentContainer .TextAreaComment {
    flex-grow: 5;
    height: 130px;
}

.CSMCommentContainer .OptionsCol {
    flex-grow: 1;
    margin-left: 10px;
}

.CSMCommentContainer .SelectComment {
    height: 40px;
}

.CSMCommentContainer .dtlKip {
    min-width: min-content;
    max-width: min-content;
}